<template>
  <div class="main-section">
    <div class="main_head d-block w-100">
      <div class="text-center">
        <img
          src="@/assets/images/logo/zignuts-logo.png"
          alt="logo"
        >
      </div>
      <div>
        <h2 class="text-center">
          Detailed Timesheet Reports
        </h2>
      </div>
      <div class="reportHead d-block w-100">
        <div class="d-inline-block headeLeft">
          <p><span>From : </span>{{ startDate }}</p>
          <p><span>To : </span>{{ endDate }}</p>
        </div>
        <div class="d-inline-block headRight">
          <p><span>Export Date : </span> {{ currentDate }}</p>
          <p><span>Total : </span>{{ totalTime }}</p>
        </div>
      </div>
    </div>
    <div
      v-for="(task, key) in tasks"
      :key="key"
      class="main-body d-block"
    >
      <div class="tabel_data d-block w-100">
        <div class="tbl_head d-block w-100">
          <div class="d-inline-block headeLeft">
            <p><span>Project : </span>{{ task["project"] }}</p>
          </div>
        </div>
        <div class="table-sec d-block w-100  custom-table">
          <table class="table w-100 ">
            <thead>
              <tr>
                <th
                  v-if="isName"
                  class="userName"
                >
                  Name
                </th>
                <th class="userDesc">
                  Description
                </th>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(project, index) in task['reports']"
                :key="index"
              >
                <td
                  v-if="isName"
                  class="text-nowrap"
                >
                  {{ project.full_name }}
                </td>
                <td class="userDesc">
                  {{ project["user_task_title"] }}
                </td>
                <td class="text-nowrap">
                  {{ project["date"] | formatDateDDMMYYYY }}
                </td>
                <td>
                  {{ formatTime(project["start_time"]) }}
                </td>
                <td>
                  {{ formatTime(project["end_time"]) }}
                </td>
                <td>
                  {{ formatTime(project["count_minutes"]) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment/moment'

export default {
  props: {

    startDate: {
      type: String,
      required: true,
    },

    endDate: {
      type: String,
      required: true,
    },

    currentDate: {
      type: String,
      required: true,
    },

    totalTime: {
      type: String,
      required: true,
    },

    tasks: {
      type: Array,
      required: true,
    },

    isName: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  methods: {
    formatTime(data) {
      return data ? moment(data, 'HH:mm:ss').format('HH:mm') : 'N/A'
    },
  },
}
</script>
<style lang="scss">
/* Main Section Styles */
.main-section {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  margin: 0 auto;
  padding: 0px 40px 40px 40px;
  background-color: #fff;

  h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background-color: #2178fb;
    padding: 4px 10px 6px 10px;
    border-radius: 4px;
    margin: 20px 0 15px 0;
  }

  /* Main Header Styles */
  .main_head {
    background-color: #fff;
    padding-bottom: 8px;
  }
}

/* Report Header Styles */
.reportHead {
  background-color: #fff;
}

.headeLeft {
  width: 81%;
}

.headRight {
  width: 14%;
}

.reportHead p,
.tbl_head p {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  margin: 0;
  white-space: nowrap;
  color: #000;
}

.reportHead span,
.tbl_head span {
  color: #2178fb;
}

/* Main Body Styles */
.main-body {
  background-color: #e5e5e5;
  margin: 0 auto;
  padding: 15px;

  /* Table Styles */
  .table {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
    padding: 8px 16px;
  }

}

/* Table Data Styles */
.tabel_data {
  // box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
}

.tabel_data:last-child {
  margin-bottom: 0;
}

/* Table Head Styles */
.tbl_head {
  background-color: #e9f2ff;
  border-radius: 4px 4px 0px 0px;
}

.tbl_head p {
  padding: 8px 16px 0 16px;
}

/* User Name and Description Styles */
.userName {
  width: 130px !important;
}

.userDesc {
  width: 300px;
  background-color: #fff !important;
}

.custom-table{
  table {
    margin-bottom: 0px !important;
  }
  th{
    background-color: #fff !important;
    border-bottom: none !important;
    text-align: left;
    font-weight: 600 !important;
    font-size: 10px !important;
    color: #2178FB;
    padding-bottom: 10px;
    padding: 0.42rem 0.25rem;
    white-space: nowrap;
    letter-spacing:0px !important;
    text-transform:none !important;
  }
  td{
    padding: 0.42rem 0.25rem;
    border-bottom: none !important;
    border-top: none !important;
    color: #888888;
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    text-align: justify;
  }
}

</style>
